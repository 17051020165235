<script>
  import Vue from 'vue';
  import BaseVue from '@frontend/Base.vue';
  import Gen from '../helper/Gen';
  Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"))

  export default {
    extends: BaseVue,
    data() {
       return {
          data:{},
       }
    },
    computed: {

    },
    async mounted() {
      this.$set(this.$root, 'page', this)
      this.getContent()
    },
    methods: {
      getContent(){
        Gen.apirest("/privacy", {},(err,resp)=>{
          if(err) console.log("About goodness error "+err)
          this.data = resp.data
          setTimeout(()=>{
            AOS.init({ once: true });
          },500)
        })
      }
    },
    watch: {
      $route() {
        this.getContent()
      }
    },
  };
</script>
<template>
  <section id="content">
        <div class="content-wrap py-0">
          <section id="page-title" class="page-title-mini">
            <div class="container clearfix">
              <h1>{{data.as_name}}</h1>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{data.as_name}}</li>
              </ol>
            </div>
          </section>
          <section class="section bg_blue_general nomargin">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading-block center aos-init aos-animate" data-aos="fade-up">
                    <h2>{{data.as_name}}</h2>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="wrap_privacy" v-html="data.as_val">
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
  </section>
</template>